// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#date-range-picker--container {
  all: unset;
  display: flex;
  background-color: transparent;
  color: inherit;
  cursor: default;
  justify-content: space-between;
  align-items: center;
  font-size: 0.85rem;
  padding: 0px 8px;
  font-weight: 300;
  width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}
#date-range-picker--container span {
  font-size: 0.85rem;
}
#date-range-picker--container .react-daterange-picker__inputGroup {
  display: none;
}
#date-range-picker--container .react-daterange-picker__range-divider {
  display: none;
}
#date-range-picker--container .date-range-picker__calendar-container {
  display: flex;
}
#date-range-picker--container .react-daterange-picker__calendar {
  margin-top: 0;
  left: 45px !important;
  top: auto !important;
  bottom: -7px !important;
  box-shadow: 0 0 0 1px #2684ff;
}
#date-range-picker--container .react-daterange-picker__wrapper:focus-within .react-daterange-picker__calendar {
  box-shadow: 0 0 0 1px #2684ff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/form/date-range-picker/custom-range/styles.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,aAAA;EACA,6BAAA;EACA,cAAA;EACA,eAAA;EACA,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,yBAAA;EACA,sBAAA;EAEA,iBAAA;EACA,6CAAA;EACA,sBAAA;AACF;AAAE;EACE,kBAAA;AAEJ;AACE;EACE,aAAA;AACJ;AAEE;EACE,aAAA;AAAJ;AAGE;EACE,aAAA;AADJ;AAIE;EACE,aAAA;EACA,qBAAA;EACA,oBAAA;EACA,uBAAA;EACA,6BAAA;AAFJ;AAKE;EACE,6BAAA;AAHJ","sourcesContent":["#date-range-picker--container {\n  all: unset;\n  display: flex;\n  background-color: transparent;\n  color: inherit;\n  cursor: default;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 0.85rem;\n  padding: 0px 8px;\n  font-weight: 300;\n  width: 100%;\n  -webkit-user-select: none;\n  -moz-user-select: none;\n  -ms-user-select: none;\n  user-select: none;\n  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);\n  box-sizing: border-box;\n  span {\n    font-size: 0.85rem;\n  }\n\n  .react-daterange-picker__inputGroup {\n    display: none;\n  }\n\n  .react-daterange-picker__range-divider {\n    display: none;\n  }\n\n  .date-range-picker__calendar-container {\n    display: flex;\n  }\n\n  .react-daterange-picker__calendar {\n    margin-top: 0;\n    left: 45px !important;\n    top: auto !important;\n    bottom: -7px !important;\n    box-shadow: 0 0 0 1px #2684ff;\n  }\n\n  .react-daterange-picker__wrapper:focus-within .react-daterange-picker__calendar {\n    box-shadow: 0 0 0 1px #2684ff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
