import {
  ADVISOR_LIST_ES,
  ADVISOR_STATS_GET,
  EXCEPTIONS_LIST_ES,
  FETCHING
} from 'constants/actions';
import moment from 'moment';
import config from '../config';
import DataProvider from './data';

class AdvisorsElasticProvider extends DataProvider {
  list(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}search/advisor/`, params)
      .then(({ data }) => {
        this.dispatch(ADVISOR_LIST_ES, {
          data: data.map(advisor => ({ ...advisor, url: '/advisor/surveillance/' }))
        });
        return data;
      });

    return promise;
  }

  // TODO: Remove this method
  listExceptions(params) {
    return this.provider
      .get(`${config.apiBase}search/account_exception/`, params, null, null, true)
      .then(({ data }) => {
        this.dispatch(EXCEPTIONS_LIST_ES, {
          exceptions: data.results,
          meta: {
            count: data.count,
            maxValue: data?.stats?.max_value?.value,
            minValue: data?.stats?.min_value?.value,
            params,
            totalPages: data.total_pages
          }
        });
        return data;
      });
  }

  // TODO: Remove this method
  downloadReport(params) {
    if (params.page && params.page_size) {
      delete params.page;
      delete params.page_size;
    }

    this.provider
      .requestNoJSON(
        `${config.apiBase}search/account_exception/download-report/`,
        null,
        params,
        true
      )
      .then(response => response.blob())
      .then(blob => {
        const objectURL = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = objectURL;
        link.download = `Drift Exception ${moment().format('ll')}.xlsx`;
        link.click();

        setTimeout(() => {
          window.URL.revokeObjectURL(objectURL);
        }, 250);
      });
  }

  getStats(params) {
    return this.provider
      .get(`${config.apiBase}search/advisor/stats/`, params)
      .then(({ data }) => this.dispatch(ADVISOR_STATS_GET, { data }));
  }
}

export default AdvisorsElasticProvider;
