/* eslint-disable no-underscore-dangle */
import {
  ACCOUNT_CLEAR,
  ACCOUNT_COUNT_EXCLUDED,
  ACCOUNT_CREATE,
  ACCOUNT_DELETE,
  ACCOUNT_EDIT,
  ACCOUNT_GET,
  ACCOUNT_LIST,
  ACCOUNT_NOTE_LIST,
  ACCOUNT_PRISM_SCORE_COMPLETED,
  ACCOUNT_PRISM_SCORE_FAILED,
  ACCOUNT_PRISM_SCORE_IN_PROGRESS,
  ACCOUNT_SAVE_CHART_IMAGE,
  ACCOUNT_SET_BREAKDOWN_CUSTOM_SECURITIES,
  ACCOUNT_UPDATE,
  ACCOUNT_UPDATE_SELECTED_IDS,
  DROP_NO_PERFORMANCE,
  FETCHING,
  FETCHING_FAILURE,
  GET_ACCOUNT_TARGET_RATING,
  GET_PRISM_SCORE,
  GET_PRISM_TARGET_HISTORY,
  SET_NO_PERFORMANCE,
  SNOOZE_ACCOUNT,
  WAKEUP_ACCOUNT
} from 'constants/actions';
import config from '../config';
import AccountsElasticProvider from './accounts.es';
import DataProvider from './data';

class AccountProvider extends DataProvider {
  get es() {
    if (!this._es) this._es = new AccountsElasticProvider({ dispatch: this._dispatch });
    return this._es;
  }

  edit(account) {
    this.dispatch(ACCOUNT_EDIT, {
      data: account
    });
  }

  list(params) {
    this.dispatch(FETCHING);
    const promise =
      // GET '/api/accounts'
      this.provider.get(`${config.apiBase}accounts/`, { ...params }).then(data => {
        if (!data.error) {
          this._params = params;

          this.dispatch(ACCOUNT_LIST, {
            data: {
              data: data.data.results || [],
              meta: {
                params,
                count: data.data.count,
                countPage: this.countPage(params, data)
              }
            }
          });
        } else
          this.dispatch(ACCOUNT_LIST, {
            data: null
          });

        return data;
      });

    return promise;
  }

  countExcludedAccounts(params) {
    this.dispatch(FETCHING);
    const promise = this.provider
      .get(`${config.apiBase}accounts/total_excluded_accounts/`, params)
      .then(data => {
        if (!data.error)
          this.dispatch(ACCOUNT_COUNT_EXCLUDED, {
            data: data.data
          });
        else this.dispatch(FETCHING_FAILURE);
        return data;
      });

    return promise;
  }

  get(id) {
    this.dispatch(FETCHING);
    const promise = this.provider.get(`${config.apiBase}accounts/${id}/`).then(data => {
      if (!data.error)
        this.dispatch(ACCOUNT_GET, {
          data: data.data
        });
      else this.dispatch(FETCHING_FAILURE);
      // Gerald, do something...
      return data;
    });

    return promise;
  }

  create(account, list = false) {
    // POST 'api/accounts'
    this.dispatch(FETCHING);
    const promise = this.provider.post(`${config.apiBase}accounts/`, account).then(resp => {
      if (!resp.error) {
        this.dispatch(ACCOUNT_CREATE, { data: resp.data });

        if (list) this.list(this.params);
      } else this.dispatch(FETCHING_FAILURE);

      return resp;
    });

    return promise;
  }

  update(account, list = false) {
    // PUT 'api/accounts/<id>'
    if (!account.id) return this.create(account);

    this.dispatch(FETCHING);
    const promise = this.provider
      .put(`${config.apiBase}accounts/${account.id}/`, account)
      .then(resp => {
        if (!resp.error) {
          this.dispatch(ACCOUNT_UPDATE, { data: resp.data });

          if (list) this.list(this.params);
        } else this.dispatch(FETCHING_FAILURE);

        return resp;
      });

    return promise;
  }

  updatePatch(accountId, data) {
    if (!accountId || !data) return null;
    const promise = this.provider
      .patch(`${config.apiBase}accounts/${accountId}/`, data)
      .then(resp => {
        if (!data.error) this.dispatch(ACCOUNT_UPDATE, { data: resp.data });
        else this.dispatch(FETCHING_FAILURE);
        return resp;
      });

    return promise;
  }

  listNotes(account) {
    return this.provider.get(`${config.apiBase}accounts/${account.id}/notes/`).then(({ data }) => {
      this.dispatch(ACCOUNT_NOTE_LIST, { data: data.results, target: account.id });
      return data;
    });
  }

  lastNote(account) {
    return this.provider.get(`${config.apiBase}accounts/${account.id}/notes/last/`);
  }

  addNote(account, note) {
    return this.provider.post(`${config.apiBase}accounts/${account.id}/notes/`, note).then(() => {
      this.listNotes(account);
    });
  }

  editNote(account, id, message) {
    return this.provider
      .put(`${config.apiBase}accounts/${account.id}/notes/${id}/`, { message })
      .then(() => {
        this.listNotes(account);
      });
  }

  removeNote(account, id) {
    return this.provider.delete(`${config.apiBase}accounts/${account.id}/notes/${id}/`).then(() => {
      this.listNotes(account);
    });
  }

  updateAnonymous(account, list = false) {
    const data = { ...account };
    if (!data.id) return this.create(account);

    const paramsUrl = this.provider.buildQueryString({ investor: 'anonymous' });
    const url = `${config.apiBase}accounts/${account.id}/${paramsUrl}`;

    this.dispatch(FETCHING);
    const promise =
      // PUT 'api/accounts/:id/?investor=anonymous'
      this.provider.patch(url, data).then(data => {
        if (!data.error) {
          this.dispatch(ACCOUNT_UPDATE, {
            data: data.data
          });

          if (list) this.list(this.params);
        } else this.dispatch(FETCHING_FAILURE);
        // Gerald, do something...
        return data;
      });

    return promise;
  }

  delete(account, list = false) {
    this.dispatch(FETCHING);
    const promise = this.provider.delete(`${config.apiBase}accounts/${account.id}/`).then(data => {
      if (!data.error) {
        this.dispatch(ACCOUNT_DELETE, {
          data: account
        });

        if (list) this.list(this.params);
      } else this.dispatch(FETCHING_FAILURE);
      // Gerald, do something...
      return data;
    });

    return promise;
  }

  clearAccount() {
    this.dispatch(ACCOUNT_CLEAR, {});
  }

  sendNoPrismErrorEmail(id) {
    const promise = this.provider.get(`${config.apiBase}accounts/${id}/send_error_message/`);
    return promise;
  }

  updatePrism(id) {
    this.dispatch(ACCOUNT_PRISM_SCORE_IN_PROGRESS, { id });

    return this.provider
      .post(`${config.apiBase}accounts/${id}/update_prism_score/`)
      .then(data => {
        if (data.error) throw new Error(data.error);
        this.dispatch(GET_PRISM_SCORE, { prism: data.data });
        this.dispatch(ACCOUNT_PRISM_SCORE_COMPLETED, { id });
      })
      .catch(error => {
        this.dispatch(ACCOUNT_PRISM_SCORE_FAILED, { id });
        throw error;
      });
  }

  registerPerformanceAbsence() {
    this.dispatch(SET_NO_PERFORMANCE);
  }

  erasePerformanceAbsence() {
    this.dispatch(DROP_NO_PERFORMANCE);
  }

  snoozeAccount(id) {
    this.dispatch(FETCHING);
    return this.provider
      .post(`${config.apiBase}accounts/${id}/snooze/`)
      .then(() => {
        this.dispatch(SNOOZE_ACCOUNT);
      })
      .catch(() => {
        this.dispatch(FETCHING_FAILURE);
      });
  }

  wakeupAccounts(id) {
    this.dispatch(FETCHING);
    return this.provider
      .post(`${config.apiBase}accounts/${id}/wakeup/`)
      .then(() => {
        this.dispatch(WAKEUP_ACCOUNT);
      })
      .catch(() => {
        this.dispatch(FETCHING_FAILURE);
      });
  }

  getTargetRating(id) {
    this.dispatch(FETCHING);
    return this.provider.get(`${config.apiBase}accounts/${id}/target_score/`).then(({ data }) => {
      this.dispatch(GET_ACCOUNT_TARGET_RATING, { data });
      return data;
    });
  }

  getPrismTargetHistory(id, params) {
    let start = null;
    let end = null;
    if (params) {
      if (params.start_date) start = params.start_date;

      if (params.end_date) end = params.end_date;
    }
    this.dispatch(FETCHING);
    return this.provider
      .get(`${config.apiBase}accounts/${id}/historical/`, params)
      .then(response => {
        const data = [];
        let count = 0;
        let displayDate = null;
        const respLength = response.data.length - 1;
        response.data.forEach(({ date, target, score }) => {
          if (count == 0 && start) displayDate = start;
          else if (count == respLength && end) displayDate = end;
          else displayDate = date;

          ++count;
          data.push({
            date: displayDate,
            targetOverall: target ? target.overall : null,
            targetTail: target ? target.tail : null,
            targetConcentrated: target ? target.concentrated : null,
            targetVolatility: target ? target.volatility : null,
            targetCorrelation: target ? target.correlation : null,
            scoreOverall: score ? score.overall : null,
            scoreTail: score ? score.tail : null,
            scoreConcentrated: score ? score.concentrated : null,
            scoreVolatility: score ? score.volatility : null,
            scoreCorrelation: score ? score.correlation : null
          });
        });
        this.dispatch(GET_PRISM_TARGET_HISTORY, { id, data });
        return data;
      });
  }

  downloadCSV(id) {
    return this.provider
      .requestNoJSON(`${config.apiBase}accounts/${id}/csv/`)
      .then(response => Promise.all([response.blob(), response]))
      .then(([data, response]) => (response.ok ? Promise.resolve(data) : Promise.reject(data)))
      .then(data => {
        const url = URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'portfolio.csv';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
  }

  addManualRiskScore(accountId, data) {
    return this.provider.post(`${config.apiBase}accounts/${accountId}/manual_tolerance_rating/`, {
      data
    });
  }

  updateSelectedId(ids) {
    this.dispatch(ACCOUNT_UPDATE_SELECTED_IDS, {
      data: {
        selected_ids: ids
      }
    });
  }

  deleteUsingId(accountId) {
    const promise = this.provider.delete(`${config.apiBase}accounts/${accountId}/`);
    return promise;
  }

  copyAccountsToModels(accountIds) {
    const promises = accountIds.map(accountId =>
      this.provider.post(`${config.apiBase}accounts/${accountId}/duplicate_as_portfolio/`)
    );
    return Promise.all(promises);
  }

  saveChartImage(data) {
    this.dispatch(ACCOUNT_SAVE_CHART_IMAGE, { data });
  }

  getReportCoverData(accountId, params) {
    return this.provider
      .get(`${config.apiBase}accounts/${accountId}/report/`, params)
      .then(({ data }) => data);
  }

  getManagers(accountId) {
    return this.provider
      .get(`${config.apiBase}accounts/${accountId}/managers/`)
      .then(({ data }) => data);
  }

  setBreakdownCustomSecurities(id, data) {
    this.dispatch(ACCOUNT_SET_BREAKDOWN_CUSTOM_SECURITIES, { id, data });
  }
}

export default AccountProvider;
