// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-toast b {
  font-weight: bold;
}

@media print {
  #Header {
    display: none;
  }
}
.notification-scan-copleted {
  display: block;
  padding-top: 3px;
  gap: 10px;
}

.notification-scan-updated,
.notification-scan-failed {
  display: flex;
  padding-top: 3px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

#Header .beta-notification {
  margin: auto;
}
#Header .beta-icon {
  margin-right: 10px;
  background: #467bb8;
  padding: 4px;
  border-radius: 3px;
  color: white;
}
#Header .btn-toast {
  padding: 0.25rem 1rem;
  margin-top: 0;
  font-size: 14px;
}
#Header .trial-icon {
  margin-right: 10px;
  background: #54ce1e;
  padding: 4px;
  padding-right: 16px;
  padding-left: 16px;
  border-radius: 1px;
  color: white;
}
#Header .default-logo {
  height: 2.1rem;
  width: 6rem;
}
#Header .custom-logo {
  max-height: 56px;
  max-width: 170px;
  margin: auto 0;
}
#Header .company-name {
  line-height: 1;
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #Header .beta-notification {
    margin-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/containers/layout/header/styles.scss"],"names":[],"mappings":"AAEA;EACE,iBAAA;AADF;;AAIA;EACE;IACE,aAAA;EADF;AACF;AAIA;EACE,cAAA;EACA,gBAAA;EACA,SAAA;AAFF;;AAKA;;EAEE,aAAA;EACA,gBAAA;EACA,SAAA;EACA,aAAA;EACA,sBAAA;AAFF;;AAME;EACE,YAAA;AAHJ;AAME;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;AAJJ;AAOE;EACE,qBAAA;EACA,aAAA;EACA,eAAA;AALJ;AAQE;EACE,kBAAA;EACA,mBAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;AANJ;AASE;EACE,cAAA;EACA,WAAA;AAPJ;AAUE;EACE,gBAAA;EACA,gBAAA;EACA,cAAA;AARJ;AAWE;EACE,cAAA;AATJ;;AAaA;EAEI;IACE,gBAAA;EAXJ;AACF","sourcesContent":["@import 'assets/scss/commons/variables';\n\n.notification-toast b {\n  font-weight: bold;\n}\n\n@media print {\n  #Header {\n    display: none;\n  }\n}\n\n.notification-scan-copleted {\n  display: block;\n  padding-top: 3px;\n  gap: 10px;\n}\n\n.notification-scan-updated,\n.notification-scan-failed {\n  display: flex;\n  padding-top: 3px;\n  gap: 10px;\n  display: flex;\n  flex-direction: column;\n}\n\n#Header {\n  .beta-notification {\n    margin: auto;\n  }\n\n  .beta-icon {\n    margin-right: 10px;\n    background: #467bb8;\n    padding: 4px;\n    border-radius: 3px;\n    color: white;\n  }\n\n  .btn-toast {\n    padding: 0.25rem 1rem;\n    margin-top: 0;\n    font-size: 14px;\n  }\n\n  .trial-icon {\n    margin-right: 10px;\n    background: #54ce1e;\n    padding: 4px;\n    padding-right: 16px;\n    padding-left: 16px;\n    border-radius: 1px;\n    color: white;\n  }\n\n  .default-logo {\n    height: 2.1rem;\n    width: 6rem;\n  }\n\n  .custom-logo {\n    max-height: 56px;\n    max-width: 170px;\n    margin: auto 0;\n  }\n\n  .company-name {\n    line-height: 1;\n  }\n}\n\n@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {\n  #Header {\n    .beta-notification {\n      margin-top: 10px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
