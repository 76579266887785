import { setBreakdownCustomSecuritiesState } from 'components/form/breakdown-custom-securities-toggle-title/utils';
import {
  ACCOUNT_DELETE,
  ACCOUNT_UPDATE,
  GET_INVESTOR_PRISM_DISTRIBUTION,
  INVESTOR_COUNT_NEW,
  INVESTOR_CREATE,
  INVESTOR_DELETE,
  INVESTOR_GET,
  INVESTOR_GET_ACCOUNTS,
  INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD,
  INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION,
  INVESTOR_GET_EXCEPTIONS,
  INVESTOR_GOAL_DELETE,
  INVESTOR_LIST,
  INVESTOR_LIST_ES,
  INVESTOR_NOTE_LIST,
  INVESTOR_SAVE_CHART_IMAGE,
  INVESTOR_SET_BREAKDOWN_CUSTOM_SECURITIES,
  INVESTOR_UPDATE,
  INVESTOR_UPDATE_SELECTED_IDS
} from 'constants/actions';
import { getUniqueObjectsArray } from 'utils/utils';

export const initialState = {
  breakdownCustomSecurities: {},
  charts: {},
  list: [],
  listMeta: {},
  notes: {},
  selectedInvestorIds: [],
  selectedInvestors: [],
  totalNewInvestors: 0,
  viewAccounts: []
};

// eslint-disable-next-line default-param-last
export default function investors(state = initialState, action) {
  switch (action.type) {
    case INVESTOR_LIST:
      return { ...state, list: action.data.data, listMeta: action.data.meta };

    case INVESTOR_LIST_ES:
      return { ...state, esList: action.data };

    case INVESTOR_GET:
      return {
        ...state,
        breakdownCustomSecurities: setBreakdownCustomSecuritiesState(
          action.data.id,
          state.breakdownCustomSecurities
        ),
        view: action.data
      };

    case INVESTOR_CREATE:
      return { ...state, view: action.data };

    case INVESTOR_UPDATE:
      return { ...state, view: state.view ? action.data : null };

    case INVESTOR_GET_ACCOUNTS:
      return { ...state, viewAccounts: action.data, viewAccountsMeta: action.meta };

    case INVESTOR_GET_ACCOUNTS_WITH_HOUSEHOLD:
      return { ...state, viewAccountsWithHousehold: action.data };

    case INVESTOR_COUNT_NEW:
      return { ...state, totalNewInvestors: action.data };

    case ACCOUNT_UPDATE: {
      const incomingAccount = action.data;
      const accounts = state.viewAccounts.map(a => {
        if (a.id === incomingAccount.id) return { ...a, ...incomingAccount };
        return a;
      });
      return { ...state, viewAccounts: [...accounts] };
    }

    case INVESTOR_GOAL_DELETE: {
      const deletedGoal = action.data;
      const accounts = state.viewAccounts.map(a => {
        if (a.goal === deletedGoal.id) return { ...a, goal: null };
        return a;
      });
      return { ...state, viewAccounts: [...accounts] };
    }

    case GET_INVESTOR_PRISM_DISTRIBUTION:
      return { ...state, prismDistribution: action.data };

    case ACCOUNT_DELETE:
      return {
        ...state,
        viewAccounts: state.viewAccounts.filter(account => account.id !== action.data.id)
      };

    case INVESTOR_UPDATE_SELECTED_IDS: {
      const { selectedInvestorIds } = action.data;
      const investors = getUniqueObjectsArray([...state.selectedInvestors, ...state.list]);
      return {
        ...state,
        selectedInvestorIds,
        selectedInvestors: investors.filter(investor =>
          selectedInvestorIds.includes(String(investor.id))
        )
      };
    }

    case INVESTOR_GET_DRIFT_LEVELS_DISTRIBUTION:
      return { ...state, driftLevelDistribution: action.data };

    case INVESTOR_GET_EXCEPTIONS:
      return { ...state, exceptions: { ...state.exceptions, [action.investorId]: action.data } };

    case INVESTOR_NOTE_LIST:
      return { ...state, notes: { ...state.notes, [action.target]: action.data } };

    case INVESTOR_SAVE_CHART_IMAGE:
      return { ...state, charts: { ...state.charts, ...action.data } };

    case INVESTOR_SET_BREAKDOWN_CUSTOM_SECURITIES:
      return {
        ...state,
        breakdownCustomSecurities: { ...state.breakdownCustomSecurities, [action.id]: action.data }
      };

    case INVESTOR_DELETE:
    default:
      return state;
  }
}
