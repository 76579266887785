import { Modal, ModalBody } from 'components/modal';
import {
  IPS_REPORT_TYPE,
  PARTIALLY_REVIEWED,
  REVIEWED
} from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router';
import { toast } from 'react-toastify';

const DEFAULT_NOTIFICATION_TIME = 60000; // 1 minute

const TakenQuestionnaireModal = ({ actorName, reviewUrl }) => {
  const [isShown, setIsShown] = useState(true);
  const hide = () => setIsShown(false);

  let url = reviewUrl;
  if (url) {
    url = new URL(url);
    url = `${url.pathname}${url.search}`;
  }

  return (
    <Modal
      id="taken-questionnaire-modal"
      className="modal-lg taken-questionnaire-modal"
      show={isShown}
      onHidden={hide}
    >
      <ModalBody>
        <h1>
          <span role="img" aria-label="celebration">
            🎉
          </span>{' '}
          Congratulations!
        </h1>
        {actorName} successfully completed the risk tolerance questionnaire.
        <br />
        <Link to={url} className="btn btn-primary" onClick={hide}>
          View Answers
        </Link>
      </ModalBody>
    </Modal>
  );
};

TakenQuestionnaireModal.propTypes = {
  actorName: PropTypes.string.isRequired,
  reviewUrl: PropTypes.string.isRequired
};

const handleHasTakenQuestionnaire = notification => {
  let url = notification.data.view_answers;
  if (url) {
    url = new URL(url);
    url = `${url.pathname}${url.search}`;
  }

  toast.success(
    () => (
      <div className="notification-toast">
        <span role="img" aria-label="celebration">
          🎉
        </span>{' '}
        {notification.data.full_name} successfully completed the risk tolerance questionnaire.
        <br />
        {url && (
          <Link to={url} className="btn btn-primary btn-small">
            View Answers
          </Link>
        )}
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME, toastId: notification.data.id }
  );
};

const handleWasLinked = notification =>
  toast.success(
    () => (
      <div className="notification-toast">
        <span role="img" aria-label="celebration">
          🎉
        </span>{' '}
        Great news! Your client {notification.actor_name} successfully linked accounts.
        <br />
        <Link to={notification.data.review_url} className="btn btn-primary btn-small">
          Review
        </Link>
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );

const handleTagged = notification =>
  toast.success(
    () => (
      <div className="notification-toast">
        {notification.data.author_name} Tagged you in a compliance note.
        <br />
        <Link
          to={`/advisor/investors/${notification.data.investor_id}/compliance-notes`}
          className="btn btn-primary btn-small"
        >
          View
        </Link>
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );

const handleExceptionUpdated = notification => {
  const message =
    notification.data.status === 'Resolved' ? (
      <div>
        <span role="img" aria-label="celebration">
          🎉
        </span>{' '}
        Congratulations! Drift exception for <b>{notification.data.account_name}</b> is resolved
      </div>
    ) : (
      <div>
        Drift exception for <b>{notification.data.account_name}</b> is now{' '}
        {notification.data.status}
      </div>
    );

  toast.success(
    () => (
      <div className="notification-toast">
        {message}
        <Link
          to={`/advisor/investors/${notification.data.investor_id}/compliance-notes`}
          className="btn btn-primary btn-small"
        >
          View
        </Link>
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );
};

const handleSurveillanceExceptionUpdated = notification => {
  const message =
    notification.data.status === 'Resolved' ? (
      <div>
        <span role="img" aria-label="celebration">
          🎉
        </span>{' '}
        Congratulations! Drift exception for <b>{notification.data.name}</b> is resolved
      </div>
    ) : (
      <div>
        Drift exception for <b>{notification.data.name}</b> is now {notification.data.status}
      </div>
    );

  const targetLink = {
    account: `/advisor/investors/${notification.data.investor_id}/compliance-notes`,
    household: `/advisor/households/${notification.data.id}`,
    investor: `/advisor/investors/${notification.data.id}/compliance-notes`
  };
  const targetType = notification.data.type.toLowerCase();

  toast.success(
    () => (
      <div className="notification-toast">
        {message}
        <Link to={targetLink[targetType]} className="btn btn-primary btn-small">
          View
        </Link>
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );
};

const handleIntegrationAccountFailed = notification => {
  let message = 'There was an error trying to sync one of your integrations.';
  if (notification?.data?.errors?.length) {
    const [error] = notification.data.errors;
    message = error.detail;
  }
  return toast.error(
    () => (
      <div className="notification-toast">
        {message}
        <br />
        <Link to="/advisor/integrations/connected" className="btn btn-primary btn-small">
          Review
        </Link>
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );
};

const handlePortfoliosBulkUpdate = (notification, setSummary) => {
  const summary = notification?.data?.summary;
  const investor = notification?.data?.investor;
  toast.success(
    () => (
      <div className="notification-toast">
        <span role="img" aria-label="celebration">
          🎉
        </span>{' '}
        Processing of the {investor ? 'Accounts' : 'Model Portfolios'} files is now complete.
        <br />
        <button
          type="button"
          className="btn btn-primary btn-small"
          onClick={() => {
            if (summary && investor) setSummary(summary, investor);
            else if (summary) setSummary(summary);
          }}
        >
          Review Results
        </button>
      </div>
    ),
    {
      toastId: notification?.data.task_id,
      autoClose: DEFAULT_NOTIFICATION_TIME,
      closeOnClick: true
    }
  );
};

const handleFileScanIQExtract = (notification, routerActions) => {
  toast.success(
    <div className="notification-scan-updated">
      <span>File text extracted. Analyzing financial information.</span>
      <button
        className="btn btn-primary btn-toast"
        type="button"
        onClick={() => {
          routerActions.push(`/advisor/ai-assistant/scan-iq/${notification.data.document_id}`);
          if (notification?.data.task_id) toast.dismiss(notification.data.task_id);
        }}
      >
        View Progress
      </button>
    </div>,
    {
      toastId: notification?.data.task_id,
      closeButton: true,
      autoClose: DEFAULT_NOTIFICATION_TIME,
      closeOnClick: false
    }
  );
};

const handleFileScanIQCompleted = (notification, routerActions) => {
  toast.success(
    <div className="notification-scan-copleted">
      <span>File scan completed successfully. Click the button below to review the results.</span>
      <button
        className="btn btn-primary btn-toast"
        type="button"
        onClick={() => {
          routerActions.push(`/advisor/ai-assistant/scan-iq/${notification.data.document_id}`);
          if (notification?.data.task_id) toast.dismiss(notification.data.task_id);
        }}
      >
        Review Results
      </button>
    </div>,
    {
      toastId: notification?.data.task_id,
      closeButton: true,
      autoClose: DEFAULT_NOTIFICATION_TIME,
      closeOnClick: false
    }
  );
};

const handleFileScanIQFailed = (notification, routerActions) => {
  toast.success(
    <div className="notification-scan-failed">
      <span>
        The file scan could not be completed. Please try again later or upload a different file.
      </span>
      <button
        className="btn btn-primary btn-toast"
        type="button"
        onClick={() => {
          routerActions.push(`/advisor/ai-assistant/scan-iq`);
          if (notification?.data.task_id) toast.dismiss(notification.data.task_id);
        }}
      >
        Try again
      </button>
    </div>,
    {
      toastId: notification?.data.task_id,
      closeButton: true,
      autoClose: DEFAULT_NOTIFICATION_TIME,
      closeOnClick: false
    }
  );
};

const handleFollowed = notification =>
  toast.success(
    () => (
      <div className="notification-toast">
        <span role="img" aria-label="alert">
          📢
        </span>{' '}
        You can now review {notification.data.name}&apos;s information, including households,
        clients, prospects, accounts, and more.
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );

const handleReviewedReport = notification => {
  const reportType = notification.data.report_type === IPS_REPORT_TYPE ? 'ips' : 'proposals';
  const reportTypeDisplay =
    notification.data.report_type === IPS_REPORT_TYPE
      ? 'Investment Policy Statement (IPS)'
      : 'Proposal';
  const reportInvestorType = notification.data.report_investor_is_prospect
    ? 'prospects'
    : 'investors';

  const messages = {
    [PARTIALLY_REVIEWED]: `has reviewed the ${reportTypeDisplay}. There are still more reviews pending.`,
    [REVIEWED]: `has reviewed the ${reportTypeDisplay}. All required parties are in accordance, and the document is complete.`
  };

  const message = messages[notification.data.report_status];
  if (!message) return;

  toast.success(
    () => (
      <div className="notification-toast">
        <div>
          <span role="img" aria-label="celebration">
            🎉
          </span>{' '}
          {notification.data.reviewer_name} {message}
        </div>
        <Link
          to={`/advisor/${reportInvestorType}/${notification.data.report_investor_id}/${reportType}/${notification.data.report_id}/`}
          className="btn btn-primary btn-small"
        >
          Review
        </Link>
      </div>
    ),
    { autoClose: DEFAULT_NOTIFICATION_TIME }
  );
};

const notificationHandlers = {
  handleExceptionUpdated,
  handleFileScanIQCompleted,
  handleFileScanIQExtract,
  handleFileScanIQFailed,
  handleFollowed,
  handleHasTakenQuestionnaire,
  handleIntegrationAccountFailed,
  handlePortfoliosBulkUpdate,
  handleReviewedReport,
  handleSurveillanceExceptionUpdated,
  handleTagged,
  handleWasLinked,
  TakenQuestionnaireModal
};
export default notificationHandlers;
